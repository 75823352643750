// Selecionar e associar eventos ao formulário de busca
export function setupSearchModel() {
    const searchModel = document.getElementById('searchModel');
    if(searchModel){
        searchModel.addEventListener('submit', handleSearchSubmit);
    }
}

function handleSearchSubmit(event) {
    event.preventDefault();
    const busca = document.getElementById('busca').value;

    setupHiddenSearchInput();
    applyLoader();
    fetchFilteredCars({ 
        busca: busca
    } );
}

export function fetchFilteredCars(filters = {}) { 

    const busca = document.getElementById('busca');
    if (busca) {
        filters.busca = busca.value;
    }

    const optionalsInput = document.getElementById('optionals');
    if (optionalsInput) {
        filters.optionals= optionalsInput.value;
    }

    $.request('onGetFilteredCars', { 
        data: filters,
        success: function (data) {
            renderResults(data);
            removeLoader();
            updateUrlWithFilters();
        },
        complete: () => {
            const requestCompletedEvent = new CustomEvent("finishRender");
            window.dispatchEvent(requestCompletedEvent)
        }
    });
}

function renderResults(data) {
    const listCars = document.getElementById('listCars');
    const pagination = document.getElementById('pagination');
    const totalCars = document.getElementById('totalCars');

    listCars.innerHTML = data["#listCars"] ?? 'Não encontramos nenhum carro com esses filtros';
    if(pagination){
        pagination.innerHTML = data["#pagination"] ?? '';
    }
    if(totalCars){
        totalCars.innerHTML = data["#totalCars"] ?? '';
    }

    const event = new Event('finishRender');
    window.dispatchEvent(event);
    setupPaginationLinks();
}

// Inicialização ao carregar o documento
export function initialFetchFilteredCars() {

    const urlParams = new URLSearchParams(window.location.search);
    const buscaUrl = urlParams.get('busca');
    if (buscaUrl) {
        document.getElementById('busca').value = buscaUrl;
    }
    if(document.getElementById('busca')){
        const busca = document.getElementById('busca').value;
        fetchFilteredCars({ 
            busca: busca
        });
        setupPaginationLinks();
        window.addEventListener('onFiltersLoaded', handleFiltersLoaded);
    }
}

function handleFiltersLoaded(event) {
    showKmPricePlaceholder();
    maskBigNumber(['#price_min', '#price_max', '#km_min', '#km_max']);
    setupHiddenSearchInput();
}

function setupHiddenSearchInput() {
    const searchModelInput = document.querySelector('input#busca');
    const formFilterCars = document.getElementById('form-filter-cars');
    const buscaInput = document.createElement('input');
    buscaInput.setAttribute('type', 'hidden');
    buscaInput.setAttribute('name', 'busca');
    buscaInput.setAttribute('id', 'buscaFilter');
    buscaInput.setAttribute('value', searchModelInput.value);
    formFilterCars.appendChild(buscaInput);

    buscaInput.value = searchModelInput.value;

    searchModelInput.addEventListener('change', function (event) {
        const buscaInput = document.getElementById('buscaFilter');
        buscaInput.value = event.target.value;
    });
}

function maskBigNumber(elementsIds) {
    elementsIds.forEach(function (elementId) {
        const originalElement = document.querySelector(elementId);
        const maskedElementId = `${elementId}_masked`;
        const maskedElement = document.querySelector(maskedElementId);

        maskedElement.addEventListener('keyup', function (e) {
            let newValue = e.target.value;
            newValue = newValue.replace(/\D/g, '');
            newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
            e.target.value = newValue;
            originalElement.value = newValue.replace(/\./g, '');
        });
    });
}

function showKmPricePlaceholder() {
    addPlaceholderForElements('#km_min_masked', '#km_max_masked', '#card-kmText .card-body div', 'km-container');
    addPlaceholderForElements('#price_min_masked', '#price_max_masked', '#card-priceText .card-body div', 'price-container');
}

function addPlaceholderForElements(elementId1, elementId2, containerSelector, containerClass) {
    const elements = [$(elementId1), $(elementId2)];
    let containersContent = '';

    elements.forEach(function (element) {
        const newElement = document.createElement('div');
        newElement.classList.add(containerClass);
        element.appendTo(newElement);
        containersContent += newElement.outerHTML;
    });

    $(containerSelector).append(containersContent);
}

// Funções de Loader
function applyLoader() {
    const skeletonCardModel = document.getElementById('skeleton-card-model');
    const listCars = document.getElementById('listCars');
    listCars.innerHTML = generateSkeletonCards(12, skeletonCardModel.innerHTML);
    $('#listCars').css('filter', 'blur(5px)');
}

function removeLoader() {
    $('#listCars').css('filter', 'none');
}

function generateSkeletonCards(count, skeletonHTML) {
    return new Array(count).fill(skeletonHTML).join('');
}

// Adicionar eventos para links de paginação
function setupPaginationLinks() {
    $(document).off('click', '.page-link', applyLoader);
    $(document).on('click', '.page-link', function() {
        applyLoader();
    });
}

// Atualizar o URL de acordo com filtros
function updateUrlWithFilters() {
    updateURLPage();
    updateURLSearch();
}

function updateURLPage() {
    const paginationInput = document.getElementById('pagination-used-input');
    const url = new URL(window.location.href);

    if (!paginationInput || paginationInput.value === '1') {
        url.searchParams.delete('page');
    } else {
        url.searchParams.set('page', paginationInput.value);
    }

    window.history.replaceState({}, '', url.toString());
}

function updateURLSearch() {
    const buscaFilterInput = document.getElementById('buscaFilter');
    const buscaInput = document.getElementById('busca');

    const url = new URL(window.location.href);

    let buscaValue = '';
    if (buscaFilterInput && buscaFilterInput.value) {
        buscaValue = buscaFilterInput.value;
    } else if (buscaInput && buscaInput.value) {
        buscaValue = buscaInput.value;
    }

    if (buscaValue !== '') {
        url.searchParams.set('busca', buscaValue);
    } else {
        url.searchParams.delete('busca');
    }

    window.history.replaceState({}, '', url.toString());
}