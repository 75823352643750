
//FUNÇÃO RESPONSÁVEL POR RETORNAR DADOS DE LARGURA, ALTURA E NAVEGADOR
export function checkMediaQuery(){
    let totalWidth  = window.innerWidth;
    let totalHeight = window.innerHeight;

    let mediaData = {
        type: 'device',
        browser: navigator.appVersion,
        deviceWidth: totalWidth,
        deviceHeight: totalHeight
    };

    if(totalWidth > 768){
        mediaData.type = 'Desktop';
        return mediaData;
    } else {
        mediaData.type = 'Mobile';
        return mediaData;
    }

}

//FUNÇÃO RESPONSÁVEL POR CRIAR UM TIMER QUE DIMINUI A EXECUÇÃO DE FUNÇÕES EXCESSIVAS
export function debounce (callback, time) {
    let interval;
    return (...args) => {
      clearTimeout(interval);
      interval = setTimeout(() => {
        interval = null;
        callback(...args);
      }, time);
    };
};

//FUNÇÃO SIMPLES QUE IMPEDE QUE UMA DIV INDEFINIDA RETORNE ERRO E TRAVE O CÓDIGO
export function hasDiv (element){

    if(typeof(element) == 'string'){
      element = document.querySelector(element);
    }

    if(element != undefined){
        return true;
    }

    // Verificando vindo do query Selector All
    if(NodeList.prototype.isPrototypeOf(element) && element.length !== 0){
      return true;
    }

    if(Array.isArray(element)){
      return element.filter((item) => item !== undefined).length > 0;
    }

    return false;
}

export function addOverlayOnPage(force = false){
  const mainElement = document.querySelector('.overlay-bg');
  setTimeout(() => {
    mainElement.classList.remove('hidden');
  }, 100);
  mainElement.classList.add('show');
  if(force){
    mainElement.classList.add('force');
  }

  sendMessageToWindow({type: 'overlay', action: 'open'});

  addListenerToOverlay();
}

function addListenerToOverlay(){
  const overlayElement = document.querySelector('.overlay-bg');
  overlayElement.addEventListener('click', function(){
    removeOverlayOnPage();
  });
}

export function removeOverlayOnPage(){
  const mainElement = document.querySelector('.overlay-bg');
  mainElement.classList.remove('show');
  mainElement.classList.remove('force');
  setTimeout(() => {
    mainElement.classList.add('hidden');
  }, 100);
  sendMessageToWindow({type: 'overlay', action: 'close'});
}

// send message to window
export function sendMessageToWindow(message){
  window.postMessage(message, '*');
}

// FUNCTION TO LOCK NAVIGATION
export function lockScreenScroll() {
  // const elementsDOM = document.querySelector('main');

  // elementsDOM.classList.add('scroll-lock');
}

export function unlockScreenScroll() {
  // const elementsDOM = document.querySelector('main');

  // elementsDOM.classList.remove('scroll-lock');
}
