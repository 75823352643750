import { lockScreenScroll } from '../utils/treatment';
import { hideMenu, hideSidebarFixed } from './sidebarFixed';
import { showPartialsFixed } from './partialsFixed';

const btnCallForm = document.querySelectorAll('.button-call-form');
const btnCallFilter = document.querySelectorAll('.button-call-filter');
const closeFormMobile = document.querySelectorAll('.btn-close-form');
const closeFilterMobile = document.querySelectorAll('.btn-close-filter');
const formMain = document.querySelector('.form-main');
const formBanner = document.querySelector('.form-banner');
const formHeader = document.querySelector('.form-header');
const formHeaderCloseBtn = document.querySelector('.form-header-overlay');
const filterHeader = document.querySelector('.header-filter');

const formMainMobile = document.querySelector('.form-main-mobile');
const filterMainMobile = document.querySelector('.filter-main-mobile');
const selectModelsWrapper = document.querySelectorAll("select[name=produto]");
const selectDepartmentWrapper = document.querySelectorAll("select[name=department]");

const headerScrolled = document.querySelector('.header-fixed');
const sidebarFixed = document.querySelector('.main-menu');

const pageURLParameter = window.location.pathname;


// FUNCTION TO CONTROL BEHAVIOR OF COTATION BUTTONS
export function buttonCotationBehavior(button) {
  let buttonSelected = document.querySelectorAll(button);

  buttonSelected.forEach((btn) => {
    btn.addEventListener('click', () => {

      if(filterHeader && filterHeader.classList.contains('filter-header-fixed')) {
        hideFixedFilter();
        lockScreenScroll();
      }

      if(document.querySelector('.header') && !document.querySelector('.header').classList.contains('show-header-scrolled')) {
        document.querySelector('.header').classList.add('show-header-scrolled');
        hideFixedFilter();
        lockScreenScroll();
      }

      if (sidebarFixed && sidebarFixed.classList.contains('d-flex')) {
        hideSidebarFixed('.sidebar');
        lockScreenScroll();
      }

      if(formHeaderCloseBtn){
        formHeaderCloseBtn.addEventListener('click', () => {
          hideFixedForm();
          lockScreenScroll();
        });
      }

      if (btn.classList.contains('button-product-cotation')) {
        const dataModel = btn.getAttribute("data-model");
        const dataId = btn.getAttribute("data-id");
        const dataDepartment = btn.getAttribute("data-department");

        if (dataId)
          swapSelect(dataId, dataModel);

          selectModelsWrapper.forEach((select) => {
          select.value = dataModel;
        });

        if(dataDepartment) {
          swapSelectDepartment(dataDepartment);

          selectDepartmentWrapper.forEach((select) => {
            select.value = dataDepartment;
          });
        }

        if (window.innerWidth >= 992) {
          if (!headerScrolled.classList.contains('show-header-scrolled')) {
            setTimeout(() => {
              if (pageURLParameter == '/novos') {
                window.scrollTo(0, 130);
              } else {
                window.scrollTo(0, 710);
              }
            }, 50);

            showPartialsFixed('.header-fixed', 'show-header-scrolled', 'hide-header-scrolled');
          }
        }

        toggleFixedForm();
        lockScreenScroll();

      } else if(btn.classList.contains('button-services-cotation')) {
        const dataModel = btn.getAttribute("data-model");

        selectModelsWrapper.forEach((select) => {
          select.value = dataModel;
        });

      } else {
        toggleFixedForm();
        lockScreenScroll();
      }
    });
  });
}

// FUNCTION TO CONTROL BEHAVIOR OF FILTERS BUTTONS
export function buttonFilterBehavior(button) {
  let buttonSelected = document.querySelectorAll(button);

  buttonSelected.forEach((btn) => {
    btn.addEventListener('click', () => {

      if((formBanner && formBanner.classList.contains('form-content-fixed')) || (formHeader && formHeader.classList.contains('form-content-open'))) {
        hideFixedForm();
        lockScreenScroll();
      }

      if (sidebarFixed && sidebarFixed.classList.contains('open-menu')) {
        hideMenu('.main-menu');
      }

      showFixedFilter();
      // lockScreenScroll();
    });
  });
}

// Toggle form
function toggleFixedForm() {
  if ((formBanner && formBanner.classList.contains('form-content-fixed')) || (formHeader && formHeader.classList.contains('form-content-open'))) {
    hideFixedForm();
  } else {
    showFixedForm();
  }
}

// FUNCTION TO SHOW FIXED FORM
function showFixedForm() {
  btnCallForm.forEach((btn) => {
    btn.innerHTML = '<i class="ai-close-light"></i><span>Fechar</span>';
  });

  let mainHeader = document.querySelector('header.header');
  mainHeader.classList.add('open-form');
  if (formBanner){
    formBanner.classList.add('form-content-fixed');
  } else if (formHeader){
    formHeader.classList.add('form-content-open');
  }
}

// BUTTON TO CLOSE FORM IN MOBILE
closeFormMobile.forEach((btnClose) => {
  btnClose.addEventListener('click', () => {
    formMainMobile.classList.remove('d-flex');
    lockScreenScroll();
  });
});

function swapSelect(id, value) {
  let html = document.getElementById(id);
    
  if (!html) {
    return
  }

  $('.swap-select').html(html.innerHTML);
  $('.swap-select').find('#carroNovo').val(value);
}

function swapSelectDepartment(value){
  let html = document.getElementById("departments");

  if (!html) {
    return
  }

  $('.swap-select-department').html(html.innerHTML);
  $('.swap-select-department').find('#department').val(value);
}

// FUNCTION TO HIDE FORM, IF ANOTHER TARGET IS ACTIVE IN DESKTOP
export function hideFixedForm() {

  let mainHeader = document.querySelector('header.header');
  mainHeader.classList.remove('open-form');

  if (formBanner){
    formBanner.classList.remove('form-content-fixed');
  } else if (formHeader){
    formHeader.classList.remove('form-content-open');
  }

  btnCallForm.forEach((btn) => {
    btn.innerHTML = '<i class="ai-message-box-light"></i><span>Solicitar cotação</span>';
  });
}

// FUNCTION TO SHOW FIXED FILTER
function showFixedFilter() {
  let usedFilter = document.getElementById('cars-used-filters');

  if (usedFilter) {
    usedFilter.classList.toggle('open-filter');
  }
}


// FUNCTION TO HIDE FILTER, IF ANOTHER TARGET IS ACTIVE IN DESKTOP
export function hideFixedFilter() {
  let usedFilter = document.getElementById('cars-used-filters');

  if (usedFilter) {
    usedFilter.classList.toggle('open-filter');
  }
}


// BUTTON TO CLOSE FILTER IN MOBILE
closeFilterMobile.forEach((btnClose) => {
  btnClose.addEventListener('click', () => {
    hideFixedFilter()
  });
})



