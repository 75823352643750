// FUNCTION TO ADD OVERLAY ON MODALS
export function popUp(selector) {
    $('main').addClass('scroll-lock');
    selector.fadeIn('fast');
}

export function popClose(form) {
    form ? $('.modal-form').fadeOut() : $('.modal-overlay').fadeOut();
    $('.iframe-youtube').attr('src', '');
    $('main').removeClass('scroll-lock');
}

// FUNCTION TO PASS INFORMATION TO POP-UP PROMOTIONS
export function modalInject(data, target) {
    var { title, img, text, video } = data;
    if (video) {
        video = `https://www.youtube.com/embed/${getYoutubeCode(video)}`;
    }
    $(target).find('.promotion-title').html(title);
    $(target).find('.promotion-img').attr('src', img);
    $(target).find('.promotion-text').html(text);
    $(target).find('.iframe-youtube').attr('src', video);
    popUp($(target));
}

function getYoutubeCode(url) {
    const code = url.split('v=')[1];
    return code;
}

export function startModalListeners(){
    // FUNCTION TO OPEN MODALS
    $('.btn-open-modal').on('click', function () {
        const el = $(this).data('target');
        const data = $(this).data('json');
        data ? modalInject(data, el) : popUp($(el));
    })

    // FUNCTION TO OPEN MODALS
    $('.modal-overlay, .btn-close-modal').on('click', function (e) {
        $(e.target).hasClass('form-close') ? popClose() : false
    }).on('click', '.pop-up-content', function (event) {
        event.stopPropagation()
    })
}

