// FUNCTION TO CONTROL HEADER/FOOTER FIXED
export function showPartialsFixed(partial, classToAdd, classToRemove) {
  const partialSelected = document.querySelectorAll(partial);
  partialSelected.forEach((partial) => {

    var partialStyle = partial.getAttribute('data-style');
    var distanceY = window.scrollY || document.documentElement.scrollTop;
    var bannerContainer = document.querySelector('.banner-container');
    var bannerContainerBottom = 150;
    if (bannerContainer) {
      let bannerContainerHeight = bannerContainer.offsetHeight;
      let bannerContainerTop = bannerContainer.offsetTop;
      bannerContainerBottom = bannerContainerTop + bannerContainerHeight;
    }

    controlPartial();

    window.addEventListener('scroll', () => {
      distanceY = window.scrollY || document.documentElement.scrollTop;
      controlPartial();
    });

    function controlPartial(){
      if (!partial.classList.contains('open-menu') && !partial.classList.contains('open-form')) {  
        if (window.scrollY > bannerContainerBottom || distanceY > bannerContainerBottom) {
          partial.classList.add(classToAdd);
  
          if (partialStyle === 'transparent') {
            partial.classList.remove('transparent-bg');
          }
  
        } else {
          partial.classList.remove(classToAdd);
          
          if (partialStyle === 'transparent') {
            partial.classList.add('transparent-bg');
          }
        }  
      }
    }
  });
}