import { addOverlayOnPage, removeOverlayOnPage } from "../utils/treatment";

const headerHeightLg = 86;
const fixedHeaderHeightLg = 56;
const headerHeightSm = 70;
const fixedHeaderHeightSm = 130;

var fixedHeaderHeigh = 0;

if (window.innerWidth < 992) {
  fixedHeaderHeigh = fixedHeaderHeightSm;
} else {
  fixedHeaderHeigh = fixedHeaderHeightLg;
}

export function tabsCreate() {
  $(".my-tabs").click(function () {
    const tab_group = $(this).data('rel')
    const tab_content = $(this).data('content')
    const tab_stuff = $(this).data('parcel')
    const content = $("#" + $(this).attr("data-content"))

    $(".my-tabs[data-rel='" + tab_group + "']").removeClass("active")
    $(this).addClass("active")
    $(".my-tabs[data-content='" + tab_content + "']").addClass("active")

    $(".my-tabs-content[data-rel='" + tab_group + "']").hide()
    $(".my-tabs-content[data-stuff='" + tab_stuff + "']").show()
    content.fadeIn();

    if (content.find(".my-tabs").length > 0) {
      const subTab = content.find(".my-tabs")
      const subTabContent = content.find(".my-tabs-content")
      subTabContent.hide()
      subTabContent.first().show()
      subTab.first().addClass("active")
    }
  });
}

export function tabsInit() {
  $(".my-tabs-content").hide()
  $(".my-tabs.active").each(function () {
    const tab_ativa = $(this).attr("data-content")
    $("#" + tab_ativa).show()

    if ($("#" + tab_ativa).find(".my-tabs").length > 0) {
      const subTab = $("#" + tab_ativa).find(".my-tabs")
      const subTabContent = $("#" + tab_ativa).find(".my-tabs-content")
      subTabContent.hide()
      subTabContent.first().show()
      subTab.first().addClass("active")

    }
  });

  $(".my-tabs-content.active.parcel").show()

}

// Função para abrir a listagem
function openNavTabsList() {
  const navtabsList = document.querySelector(".navtabs-list");
  if (navtabsList && !navtabsList.classList.contains("open")) {
    navtabsList.classList.add("open");
    addOverlayOnPage();
  }

  // get the position of the .navtabs-list
  const navtabsListPosition = navtabsList.getBoundingClientRect().top;
  window.scrollBy(0, navtabsListPosition - fixedHeaderHeigh);

}

// Função para fechar a listagem
function closeNavTabsList() {
  const navtabsList = document.querySelector(".navtabs-list");
  if (navtabsList && navtabsList.classList.contains("open")) {
    navtabsList.classList.remove("open");
    removeOverlayOnPage();
  }
}

// Função para configurar o evento de clique no elemento com ID selectedNavTab
export function setupNavTabsListToggle() {
  const selectedNavTab = document.getElementById("selectedNavTab");

  if (selectedNavTab) {
    selectedNavTab.addEventListener("click", () => {
      const navtabsList = document.querySelector(".navtabs-list");
      if (navtabsList && navtabsList.classList.contains("open")) {
        closeNavTabsList();
      } else {
        openNavTabsList();
      }
    });
  }

  window.addEventListener('message', function (event) {
    if (event.data.type === 'overlay' && event.data.action === 'close') {
      closeNavTabsList();
    }
  });
} 

// Função para configurar o evento de clique nos itens da listagem
export function setupNavTabsItemClick() {
  const navtabsList = document.querySelector(".navtabs-list");
  const selectedNavTab = document.getElementById("selectedNavTab");

  if (navtabsList && selectedNavTab) {
    navtabsList.addEventListener("click", (event) => {
      const item = event.target.closest(".navtabs-item[data-navtabs]");
      if (window.innerWidth < 992) {
        if (item && navtabsList.contains(item)) {
          selectedNavTab.innerHTML = item.innerHTML;

          // Fecha a lista
          closeNavTabsList();
        }
      }
    });
  }
}