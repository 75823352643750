import { activateHeaderStyle, desactivateHeaderStyle } from "./sidebarFixed";

// FUNCTION TO CLOSE ALL DROPDOWNS IN NAVBAR
export function closeAllNavbarDropdown() {
  const activeDropdowns = document.querySelectorAll('.navbar-dropdown-list-active');
  const activeNavMenu = document.querySelectorAll('.navbar-link-active');

  activeDropdowns.forEach(dropdown => {
    dropdown.classList.remove('navbar-dropdown-list-active');
  });

  activeNavMenu.forEach(navMenu => {
    navMenu.classList.remove('navbar-link-active');
  }); 
}

// FUNCTION TO CONTROL DROPDOWNS ONLY IN NAVBAR MENUS
export function showNavbarDropdown(dropdown, content) {

  const dropdownSelected = document.querySelectorAll(dropdown);

  if (dropdownSelected) {

    dropdownSelected.forEach((dropdownItem) => {

      dropdownItem.addEventListener('click', (event) => {
        event.stopPropagation();  // Previne que outros eventos de clique interfiram

        // Fecha todos os dropdowns antes de abrir o selecionado
        closeAllNavbarDropdown();

        // Abre o dropdown atual
        const dropdownContent = dropdownItem.querySelector(content);
        if (dropdownContent) {

          let nearHeader = dropdownContent.closest('header');
          activateHeaderStyle(nearHeader);

          // activeNavMenu
          dropdownItem.classList.add('navbar-link-active');

          dropdownContent.classList.add('navbar-dropdown-list-active');
          // Fecha o dropdown se o botão de fechar for clicado
          const closeDropdown = dropdownContent.querySelector('.close-dropdown');
          if (closeDropdown) {
            closeDropdown.addEventListener('click', (e) => {
              e.stopPropagation();  // Previne que o clique feche imediatamente o dropdown após abrir
              dropdownContent.classList.remove('navbar-dropdown-list-active');
            });
          } 

          // Fecha o dropdown se clicar fora dele
          document.addEventListener('click', (e) => {
            if (!dropdownItem.contains(e.target)) {
              dropdownItem.classList.remove('navbar-link-active');
              dropdownContent.classList.remove('navbar-dropdown-list-active');
              desactivateHeaderStyle(nearHeader); 
            }
          }, { once: true }); // Ouvinte removido após a execução

        } 

      });
    });
  }
}

export function showDropdownSub(dropdown, content) {
  const dropdownSelected = document.querySelectorAll(dropdown);
  
  if (dropdownSelected) {
    dropdownSelected.forEach((dropdownItem) => {
      dropdownItem.addEventListener('click', (event) => {
        event.stopPropagation();

        const dropdownContent = dropdownItem.nextElementSibling;
        if (dropdownContent) {
          dropdownItem.classList.toggle('dropdown-subtitle-active');
          dropdownContent.classList.toggle('dropdown-links-list-active');
        }
      });
    });
  }
}