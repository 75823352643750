// Função para carregar a configuração inicial dos carros novos
export function onLoadNewCars() {
  if(document.querySelector('.site-novos')){
    setupListNewCars();
  }
  if (document.querySelector(".site-novos-detalhe")) {
    setupNewCarsDetails();
  }
}

function setupListNewCars(){
  setupSeriesListToggle();
  setupSeriesItemClick();
  setupScrollEvent();
  setupActiveLinkOnScroll();
  setupCountActiveCars();
  setupCarFilter();
  setupModalToggle();
  createButtosFilter();
  saveFiltersOnSessionStorage();
  setupFilterButtonsClick();
  setupCheckboxListeners();
  toggleCarDetails();
  listenerFilterButton();
}

function setupNewCarsDetails() {
  versionListener();
}

function versionListener() {
  const versions = document.querySelectorAll(".car-version-content");
  versions.forEach((version) => {
    const button = version.querySelector(".show-more");
    button.addEventListener("click", () => {
      const content = version.querySelector(".content-text ul");
      content.classList.add("open");
      button.classList.toggle("hidden");
    });
  });
}

// Função para abrir a listagem
function openSeriesList() {
  const seriesList = document.querySelector(".series-list");
  if (seriesList && !seriesList.classList.contains("open")) {
    seriesList.classList.add("open");
  }
}

// Função para fechar a listagem
function closeSeriesList() {
  const seriesList = document.querySelector(".series-list");
  if (seriesList && seriesList.classList.contains("open")) {
    seriesList.classList.remove("open");
  }
}

// Função para configurar o evento de clique no elemento com ID selectedSeriesCar
function setupSeriesListToggle() {
  const selectedSeriesCar = document.getElementById("selectedSeriesCar");

  if (selectedSeriesCar) {
    selectedSeriesCar.addEventListener("click", () => {
      const seriesList = document.querySelector(".series-list");
      if (seriesList && seriesList.classList.contains("open")) {
        closeSeriesList();
      } else {
        openSeriesList();
      }
    });
  }
}

// Função para configurar o evento de clique nos itens da listagem
function setupSeriesItemClick() {
  const seriesList = document.querySelector(".series-list");
  const selectedSeriesCar = document.getElementById("selectedSeriesCar");

  if (seriesList && selectedSeriesCar) {
    seriesList.addEventListener("click", (event) => {
      const item = event.target.closest(".series-item[data-series]");

      if (item && seriesList.contains(item)) {
        selectedSeriesCar.innerHTML = item.innerHTML;

        // Fecha a lista
        closeSeriesList();
      }
    });
  }
}

// Função para configurar o evento de rolagem da página
function setupScrollEvent() {
  window.addEventListener("scroll", () => {
    const filterDiv = document.querySelector(".new-models-list-series-filters");
    const modelsContainer = document.querySelector(".new-models-list-container");
    if (filterDiv) {
      if (window.scrollY > 150) {
        filterDiv.classList.add("fixed");
        modelsContainer.classList.add("fix-margin");
      } else {
        filterDiv.classList.remove("fixed");
        modelsContainer.classList.remove("fix-margin");
      }
    }
  });
}

// Função para adicionar classe 'active' ao link correspondente ao container visível
function setupActiveLinkOnScroll() {
  const sections = document.querySelectorAll(".new-models-list-content .anchor-target");
  const seriesItems = document.querySelectorAll(".series-list .series-item");
  const selectedSeriesCar = document.getElementById("selectedSeriesCar");

  if (sections.length > 0 && seriesItems.length > 0 && selectedSeriesCar) {
    // Inicializa com o primeiro item ativo
    let activeLink = document.querySelector(`.series-list .series-item[data-series="${sections[0].getAttribute("id")}"]`);
    if (activeLink) {
      activeLink.classList.add("active");
      selectedSeriesCar.innerHTML = activeLink.innerHTML;
    }

    window.addEventListener("scroll", () => {
      let newActiveLink = null;

      sections.forEach((section) => {
        if (section.getBoundingClientRect().top <= 50) {
          const sectionId = section.getAttribute("id");
          newActiveLink = document.querySelector(`.series-list .series-item[data-series="${sectionId}"]`);
        }
      });

      if (newActiveLink && newActiveLink !== activeLink) {
        if (activeLink) {
          activeLink.classList.remove("active");
        }
        newActiveLink.classList.add("active");
        activeLink = newActiveLink;
        selectedSeriesCar.innerHTML = newActiveLink.innerHTML;
      }
    });
  }
}

// Função para contar quantos carros estão ativos em tela
function setupCountActiveCars() {
  const seriesItems = document.querySelectorAll(".new-models-card-active");

  if (seriesItems) {
    const countContainer = document.querySelectorAll(".totalCars");
    if (countContainer) {
      countContainer.forEach(element => {
        element.innerHTML = seriesItems.length;
      });
    }
  }
}

// Função para filtrar os carros com base nos filtros aplicados
function setupCarFilter() {
  const filterInputs = document.querySelectorAll(".filter-options input[type='checkbox']");

  if (filterInputs) {
    filterInputs.forEach((input) => {
      input.addEventListener("change", () => {
        filterCars();
      });
    });
  }
}

// Função para salvar os filtros ativos na sessão
function saveFiltersOnSessionStorage() {
  // Salva os filtros ativos na sessão separados por tipo
  const activeBodyTypes = Array.from(document.querySelectorAll(".filter-options input[name='bodyType']:checked")).map(input => {
    const value = removeAccentsAndWhitespace(input.value);
    if (value === 'seda' || value === 'sedan' || value === 'sedã') {
      return 'seda';
    }
    return value;
  });
  const activeFuelTypes = Array.from(document.querySelectorAll(".filter-options input[name='fuelType']:checked")).map(input => removeAccentsAndWhitespace(input.value));

  const activeFilters = {
    bodyType: activeBodyTypes,
    fuelType: activeFuelTypes
  };

  sessionStorage.setItem("activeFilters", JSON.stringify(activeFilters));
}

// Função para recuperar os filtros ativos da sessão
function getActiveFiltersFromSessionStorage() {
  const activeFilters = sessionStorage.getItem("activeFilters");
  if (activeFilters) {
    return JSON.parse(activeFilters);
  }
  return null;
}

// Função para remover acentos e espaços de uma string
function removeAccentsAndWhitespace(str) {
  return str.normalize("NFD").replace(/[̀-ͯ]/g, "").trim().toLowerCase();
}

// Função para filtrar os carros com base nos filtros ativos
function filterCars() {
  saveFiltersOnSessionStorage();

  const getActiveFilters = getActiveFiltersFromSessionStorage();
  const activeBodyTypes = getActiveFilters.bodyType;
  const activeFuelTypes = getActiveFilters.fuelType;
  const carCards = document.querySelectorAll(".new-models-card");

  carCards.forEach((card) => {
    const carBodyTypes = card.getAttribute("data-bodytype").split(", ").map(type => removeAccentsAndWhitespace(type));
    const carFuelTypes = card.getAttribute("data-fueltype").split(", ").map(type => removeAccentsAndWhitespace(type));

    const matchesBodyType = activeBodyTypes.length === 0 || activeBodyTypes.some(type => carBodyTypes.includes(type));
    const matchesFuelType = activeFuelTypes.length === 0 || activeFuelTypes.some(type => carFuelTypes.includes(type));

    if (matchesBodyType && matchesFuelType) {
      card.classList.add("new-models-card-active");
    } else {
      card.classList.remove("new-models-card-active");
    }
  });

  // Atualiza a contagem de carros ativos
  setupCountActiveCars();
  // Ocultar seções que não têm nenhum carro ativo
  hideEmptySections();
}

// Função para ocultar seções que não têm nenhum carro ativo
function hideEmptySections() {
  const sections = document.querySelectorAll(".new-models-list-content");
  sections.forEach((section) => {
    const activeCards = section.querySelectorAll(".new-models-card-active");

    const seriesList = document.querySelector(".series-list");
    const seriesItem = seriesList.querySelector(`.series-item[data-series="${section.getAttribute("data-sectionId")}"]`);

    if (activeCards.length === 0) {

      section.classList.remove("new-models-list-content-active");

      if (seriesItem) {
        seriesItem.classList.add("hidden");
      }
    } else {

      section.classList.add("new-models-list-content-active");

      if (seriesItem) {
        seriesItem.classList.remove("hidden");
      }
    }
  });
}

// Função para inicializar o listener do modal
function setupModalToggle() {
  const modal = document.querySelector(".new-models-filters-modal");

  // Abre o modal
  const openModalButton = document.getElementById("btn-more-filters-new-cars");
  openModalButton.addEventListener("click", () => {
    setupModalOpen(modal);
  });

  // Fecha o modal  
  const closeModalButton = document.querySelectorAll(".close-modal-js");
  closeModalButton.forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      setupModalClose(modal);
      setupFilterButtonsClick();
    });
  });

  // Limpa os filtros
  const clearFiltersButton = document.querySelector(".clearFilters-js");
  clearFiltersButton.addEventListener("click", (e) => {
    e.preventDefault();
    setupClearFilters();
  });
}

// Função para abrir o modal de filtros
function setupModalOpen(modal) {
  if (modal) {
    modal.classList.add("new-models-filters-modal-open");
  }
}

// Função para fechar o modal de filtros
function setupModalClose(modal) {
  if (modal) {
    modal.classList.remove("new-models-filters-modal-open");
  }
}

// Função para limpar todos os filtros
function setupClearFilters() {
  const filterInputs = document.querySelectorAll(".filter-options input[type='checkbox']");
  filterInputs.forEach((input) => {
    input.checked = false;
  });
  filterCars();
  setupFilterButtonsClick();
}

// Função para configurar a interação com os botões de filtro
function setupFilterButtonsClick() {

  const filterButtonsContainer = document.querySelector(".filters-buttons-js");
  const filterInputs = document.querySelectorAll(".filter-options input[type='checkbox']");

  filterInputs.forEach((input) => {
    const filterValue = input.value;
    const filterType = input.name;

    // Se o checkbox estiver marcado, adicionar a classe active no botão
    const buttonElement = filterButtonsContainer.querySelector(`button[data-filterValue="${filterValue}"][data-filterType="${filterType}"]`);
    if (input.checked) {
      buttonElement.classList.add("active");
      buttonElement.classList.remove("hidden");
    } else {
      // se não for estático, remover o botão
      const isStatic = buttonElement.getAttribute("data-static");
      if (isStatic !== "true") {
        buttonElement.classList.remove("active");
        buttonElement.classList.add("hidden");
      } else {
        buttonElement.classList.remove("active");
      }
    }
  });
}

// Função para criar um botão de filtro
function createFilterButton(filterValue, filterType) {
  const button = document.createElement("button");
  button.setAttribute("data-filterValue", filterValue);
  button.setAttribute("data-static", "false");
  button.setAttribute("data-filterType", filterType);
  button.classList.add("btn");
  button.classList.add("hidden");

  const icon = document.createElement("i");
  icon.className = "ai-plus-light";
  button.appendChild(icon);

  const span = document.createElement("span");
  span.textContent = filterValue;
  button.appendChild(span);

  return button;
}

// Função para monitorar mudanças nos checkboxes
function setupCheckboxListeners() {
  const checkboxes = document.querySelectorAll(".filter-options input[type='checkbox']");

  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener("change", () => {
      setupFilterButtonsClick();
    });
  });
}

function listenerFilterButton() {
  const filterButtons = document.querySelectorAll(".filters-buttons-js button");

  console.log("started Listeners");

  filterButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const filterValue = button.getAttribute("data-filterValue");
      const filterType = button.getAttribute("data-filterType");
      const checkbox = document.querySelector(`input[name="${filterType}"][value="${filterValue}"]`);

      if (button.classList.contains("active")) {
        button.classList.remove("active");
        const isStatic = button.getAttribute("data-static");

        
        if (isStatic !== "true") {
          button.classList.add("hidden");
        }

        if (checkbox) {
          checkbox.checked = false;
        }

      } else {
        button.classList.add("active");
        button.classList.remove("hidden");

        if (checkbox) {
          checkbox.checked = true;
        }
      }

      filterCars();
    });
  });
}

function createButtosFilter() {
  // Criar botões estáticos de acordo com os filtros iniciais
  const filterButtonsContainer = document.querySelector(".filters-buttons-js");
  const filterInputs = document.querySelectorAll(".filter-options input[type='checkbox']");
  // Se não existir um botão para o filtro, criar um botão estático
  filterInputs.forEach((input) => {
    const filterValue = input.value;
    const filterType = input.name;
    const button = filterButtonsContainer.querySelector(`button[data-filterValue="${filterValue}"][data-filterType="${filterType}"]`);
    if (!button) {
      const button = createFilterButton(filterValue, filterType);
      filterButtonsContainer.appendChild(button);
    }

    // Se o checkbox estiver marcado, adicionar a classe active no botão
    const buttonElement = filterButtonsContainer.querySelector(`button[data-filterValue="${filterValue}"][data-filterType="${filterType}"]`);
    if (input.checked) {
      buttonElement.classList.add("active");
      buttonElement.classList.remove("hidden");
    } else {
      // se não for estático, remover o botão
      const isStatic = buttonElement.getAttribute("data-static");
      if (isStatic !== "true") {
        buttonElement.classList.remove("active");
        buttonElement.classList.add("hidden");
      }
    }
  });
}

function toggleCarDetails() {
  const cars = document.querySelectorAll(".new-models-card");

  cars.forEach((cardCar) => {
    const openButton = cardCar.querySelector(".btn-open-detail");
    openButton.addEventListener("click", () => {
      if(cardCar.classList.contains("new-models-card-open")){
        closeCarDetail(cardCar);
      } else {
        closeOtherCarDetails(cardCar);
      }
    });

    const closeButtons = cardCar.querySelectorAll(".btn-close-detail");
    closeButtons.forEach((button) => {
      button.addEventListener("click", () => {
        closeCarDetail(cardCar);
      });
    });
  });


}

function closeCarDetail(carCard){
  const cardDetails = carCard.querySelector(".new-models-card-detail");
  cardDetails.style.top = `0px`;
  carCard.classList.remove("new-models-card-open");
  
}

function openCarDetails(carCard) {
const cardTop = carCard.getBoundingClientRect().top;
const scrollPosition = window.scrollY - 120;
const cardTopPosition = cardTop + scrollPosition;

// Ajusta o posicionamento do card de detalhes se a tela for maior que 768px
const carCardHeight = carCard.offsetHeight;
const cardDetails = carCard.querySelector(".new-models-card-detail"); 
const parentElement = carCard.closest('.new-models-list-content-models');
const distanceToParentTop = carCard.offsetTop - parentElement.offsetTop;
if (window.innerWidth > 768) {
  cardDetails.style.top = `${distanceToParentTop + carCardHeight + 50}px`;
} else {
  cardDetails.style.top = `0px`;
}

carCard.classList.add("new-models-card-open"); 
setTimeout(() => {
  window.scrollTo({
    top: cardTopPosition,
    behavior: "smooth",
  });
  
}, 350);
}

function closeOtherCarDetails(carCard) {
  const cars = document.querySelectorAll(".new-models-card");
  cars.forEach((card) => {
    if (card !== carCard) {
      const cardDetails = card.querySelector(".new-models-card-detail");
      cardDetails.style.top = `0px`;
      card.classList.remove("new-models-card-open");
    }
  });
  
  setTimeout(() => {
    openCarDetails(carCard);
  }, 250);
}