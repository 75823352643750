export function handleSetMoreHeight(elements, breakpoint) {
  if(window.innerWidth > breakpoint) {
    const elementSelect = document.querySelectorAll(elements);
    const elementsHeightArray = [];

    elementSelect.forEach((element) => {
      const elementHeight = element.offsetHeight;
      elementsHeightArray.push(elementHeight);
    });

    const elementsHeightArrayMax = Math.max(...elementsHeightArray);

    elementSelect.forEach((element) => {
      element.style.cssText = `height: ${elementsHeightArrayMax}px;`
    });
  }
}

export function getHeightDifference() {

  if (window.innerWidth < 768) {
    return null;
  }

    const mainBanner = document.getElementById('main-banner');
    const formMain = document.querySelector('.form-main');

    if (mainBanner && formMain) {
        const mainBannerHeight = mainBanner.offsetHeight;
        const formMainHeight = formMain.offsetHeight;

        if (formMainHeight > (mainBannerHeight - 40)) {
          mainBanner.style.minHeight = `${formMainHeight + 40}px`;
        } else { 
          formMain.style.minHeight = 'unset';
        }

        return Math.abs(mainBannerHeight - formMainHeight);
    }

    return null;
}