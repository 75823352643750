import { startModalListeners } from "./modal";

export function copyCtaBannerTitle(ctaId, targetID) {
    const cta = document.getElementById(ctaId);
    const target = document.getElementById(targetID);
    
    if (cta && !target) {
        cta.classList.remove('d-none');
        cta.classList.add('d-flex','pb-2');
        return;
    }

    if (!cta || !target) {
        return;
    }

    target.innerHTML = cta.innerHTML;
    startModalListeners();
}

export function readMoreText(ctaClass, textComponentClass) {
    const cta = document.querySelectorAll(`.${ctaClass}`);
    
    cta.forEach(readMoreBtn => {
        var textComponent = readMoreBtn.closest(`.${textComponentClass}`);

        if(!textComponent) {
           textComponent = readMoreBtn.parentElement.querySelector(`.${textComponentClass}`);  
        }
        
        if (textComponent) {
            readMoreBtn.addEventListener('click', () => {
                textComponent.classList.toggle('open');
            });
        } else {
            console.log('No text component found');
        }
    });

}