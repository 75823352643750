import { fetchFilteredCars } from "./actionsFilters";


export function setupPremiumSelectionActions() {

    let premiumSelectionExist = document.getElementById('premiumSelecionExist');
    let cardAlreadyExist = document.getElementById('card-premium-selection');

    if (premiumSelectionExist && !cardAlreadyExist) {


        var htmlFilter = `
        <div class="card-container" id="card-premium-selection">
            <div class="card">
                <div class="card-header" id="heading-premium_selecion">
                    <div class="clear" style="display: none;margin: auto;" id="clear_premium_selecion" data-clear-key="premium_selecion">
                        <i class="icon-fechar-regular"></i>
                        Limpar filtro
                    </div>
                </div>
    
                <div id="collapse-premium_selecion" class="collapse show" aria-labelledby="collapse-premium_selecion">
                    <div class="card-body">
                        <ul class="list-premium_selecion-wrapper used-model-filter-expanded-list">
                            <input type="hidden" name="optionals" value="" id="optionals">
                            <input name="premium_selecion" id="bmw_premium_selecion" type="radio" class="radio-clicked" data-name="bmw_premium_selecion" value="bmw_premium_selecion">
                            <label class="categories-label" for="bmw_premium_selecion">
                                <li class="categories-label-item">
                                    <i class="logo-premium icon-logo-bmw"></i>
                                    <span class="categories-label-name">BMW Premium Selection</span>
                                </li>
                            </label>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        `;

        $('#main-filters').prepend(htmlFilter);

        syncUrlPremiumSelectionToFilter();

        startListenersPremiumSelection();

        // delete premiumSelectionExist
        premiumSelectionExist.classList.add('d-none');

    }

}

export function checkUrlParams() {
    const url = window.location.href;
    const premiumSelecionParam = url.includes('premium_selecion=')

    if (premiumSelecionParam) {
        syncUrlPremiumSelectionToFilter();
    }
}

function syncUrlPremiumSelectionToFilter() {
    const url = window.location.href;
    const premiumSelecionParam = url.includes('premium_selecion=');
    const clearPremiumSelecion = document.getElementById('clear_premium_selecion');
    const optionalsInput = document.getElementById('optionals');

    if (premiumSelecionParam) {

        const termo = url.split('premium_selecion=')[1];

        const termosConfig = {
            bmw_premium_selecion: 'bmw_premium_selecion',
            mini_next: 'mini_next',
            motorrad_premium_selecion: 'motorrad_premium_selecion'
        };

        if (termosConfig[termo]) {
            const id = termosConfig[termo];
            const input = document.getElementById(id);
            const label = document.querySelector(`label[for=${id}] li`);

            if (input) input.checked = true;
            if (label) label.classList.add('checked');

            if (optionalsInput) {
                optionalsInput.value = "BMW Premium Selection";
            }

            fetchFilteredCars();
        }

        if (clearPremiumSelecion) {
            clearPremiumSelecion.style.display = 'block';
        }

        syncPremiumSelectionAndOptionsInput();

    } else {

        const inputs = document.querySelectorAll('input[name="premium_selecion"]');
        const labels = document.querySelectorAll('label[for^="bmw_premium_selecion"], label[for^="mini_next"], label[for^="motorrad_premium_selecion"]');
        const optionalsInput = document.getElementById('optionals');

        optionalsInput.value = '';

        inputs.forEach(input => {
            input.checked = false;
            labels.forEach(label => {
                const labelChild = label.querySelector('li');
                labelChild.classList.remove('checked');
            });
        });

        if (clearPremiumSelecion) {
            clearPremiumSelecion.style.display = 'none';
        }
    }
}

function startListenersPremiumSelection() {
    listenSliders();
    listenPremiumSelectionInput();
    listenerPremiumSelectionClearButton();
}

function listenPremiumSelectionInput() {
    const inputs = document.querySelectorAll('input[name="premium_selecion"]');

    inputs.forEach(input => {
        input.addEventListener('change', () => {
            syncPremiumSelectionAndOptionsInput();
            fetchFilteredCars();
        });
    });
}

function listenerPremiumSelectionClearButton() {
    const clearPremiumSelecion = document.getElementById('clear_premium_selecion');

    if (clearPremiumSelecion) {
        clearPremiumSelecion.addEventListener('click', () => {
            clearUrlPremiumSelection();
            syncUrlPremiumSelectionToFilter();
            syncPremiumSelectionAndOptionsInput();
            fetchFilteredCars();
        });
    }
}

function syncPremiumSelectionAndOptionsInput() {
    const input = document.querySelector('input[name="premium_selecion"]:checked');
    const optionalsInput = document.getElementById('optionals');

    if (input && optionalsInput) {
        if (input.value == 'bmw_premium_selecion') {
            optionalsInput.value = "BMW Premium Selection";
            disableBrandFilters();
        }
    } else if (optionalsInput) {
        optionalsInput.value = '';
        enableBrandFilters(); 
    }

}

function clearUrlPremiumSelection() {
    const url = window.location.href.split('?')[0];
    window.history.pushState({}, '', url);
}

function disableBrandFilters() {
    const brandFilter = document.querySelector('#card-brands');
    brandFilter.classList.add('disabled');
}

function enableBrandFilters() {
    const brandFilter = document.querySelector('#card-brands');
    brandFilter.classList.remove('disabled');
} 