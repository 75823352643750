let swiperBanners;

export function swipersInit() {
  swiperBanners = new Swiper('.swiper-banners', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    speed: 700,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination.banners',
      clickable: true,
    },
  });

  const swiperNewModelsHome = new Swiper('.swiper-new-models-home', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next.new-models-home',
      prevEl: '.swiper-button-prev.new-models-home'
    },
    pagination: {
      el: '.swiper-pagination.models',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 3,
      },
    },
  });

  const swiperCarHighlightsTabs = new Swiper('.car-highlights-tabs', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: "auto",
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next.car-highlights-tab-nav',
      prevEl: '.swiper-button-prev.car-highlights-tab-nav'
    },
  });

  const swiperTypeModelsHome = new Swiper('.swiper-type-models-home', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    centerInsufficientSlides: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.type-models-home',
      prevEl: '.swiper-button-prev.type-models-home'
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 15,
      },
      1400: {
        slidesPerView: 7,
        spaceBetween: 15,
      },
    },
  });

  const swiperNewModels = new Swiper('.swiper-new-model', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next.new-model',
      prevEl: '.swiper-button-prev.new-model',
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1600: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });

  const swiperNewModelsPerformance = new Swiper('.new-model-performance', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination.performance',
      clickable: true,
    },
    breakpoints: {
      540: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 4,
      },
    },
  });

  const swiperNewModelsAttachments = new Swiper('.new-model-attachments', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination.attachments',
      clickable: true,
    },
    breakpoints: {
      540: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiperNewModelsBenefits = new Swiper('.new-model-benefits', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination.benefits',
      clickable: true,
    },
    breakpoints: {
      540: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiperNewModelsReasons = new Swiper('.new-model-reasons', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination.reasons',
      clickable: true,
    },
    breakpoints: {
      540: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiperNewModelVersion = new Swiper('.swiper-new-model-version', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.version',
      prevEl: '.swiper-button-prev.version',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      }
    },
  });

  const swiperLocations = new Swiper('.swiper-locations', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.locations',
      prevEl: '.swiper-button-prev.locations',
    },
    pagination: {
      el: '.swiper-pagination.locations',
      clickable: true,
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
    },
  });

  const swiperPromotion = new Swiper('.swiper-promotion', {
    slidesPerView: 1,
    watchOverflow: true,
    observer: true,
    navigation: {
      nextEl: '.swiper-button-next.promotion',
      prevEl: '.swiper-button-prev.promotion',
    },
    pagination: {
      el: '.swiper-pagination.promotion',
      clickable: true,
    },
    breakpoints: {
      900: {
        slidesPerView: 2,
        spaceBetween: 30,
      },

      1366: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  const blogInternalRelatedSwiper = new Swiper('.blog-internal-related-slider', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    slidesPerView: 1,
    watchOverflow: true,
    watchOverflow: true,
    navigation: {
      nextEl: '.swiper-button-next.blog-internal',
      prevEl: '.swiper-button-prev.blog-internal',
    },
    pagination: {
      el: '.swiper-pagination.blog-internal',
      clickable: true
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    }
  });


  const swiperUsedModelInternalStamp = new Swiper('.used-model-internal-stamps-slider', {
    clickable: true,
    slidesPerView: 3,
    watchOverflow: true,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination.stamp',
      clickable: true
    },
  });

  const swiperUsedModelInternalThumbs = new Swiper('.used-model-internal-slider-thumbs', {
    slidesPerView: 2.5,
    freeMode: true,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next.used-thumbs',
      prevEl: '.swiper-button-prev.used-thumbs',
    },
    breakpoints: {
      430: {
        slidesPerView: 3.5,
      },
      480: {
        slidesPerView: 4.5,
      },
      768: {
        slidesPerView: "auto",
      }
    }
  });

  const usedModelInternalSwiper = new Swiper('.used-model-internal-slider-content', {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next.used',
      prevEl: '.swiper-button-prev.used',
    },
    clickable: true,
    thumbs: {
      swiper: swiperUsedModelInternalThumbs,
    },
  });

  const swiperUsedModelInternalRelated = new Swiper('.used-model-internal-related-slider', {
    clickable: true,
    slidesPerView: 1,
    watchOverflow: true,
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    pagination: {
      el: '.swiper-pagination.related',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next.related',
      prevEl: '.swiper-button-prev.related',
    },
    breakpoints: {
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
      }
    }
  });


  const swiperHighlights = new Swiper('.swiper-highlights', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.highlights',
      prevEl: '.swiper-button-prev.highlights',
    },
    pagination: {
      el: '.swiper-pagination.highlights',
      clickable: true,
    },
    pagination: {
      el: '.swiper-pagination.highlights',
      clickable: true
    },
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });

  const swiperCategories = new Swiper('.swiper-categories', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 4,
    navigation: {
      nextEl: '.swiper-button-next.categories',
      prevEl: '.swiper-button-prev.categories',
    },
    breakpoints: {
      992: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
    },
  });

  const swiperNewFamilies = new Swiper('.swiper-new-family-cars', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    breakpoints: {
      992: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next.new-family-cars',
      prevEl: '.swiper-button-prev.new-family-cars',
    },
    pagination: {
      el: '.swiper-pagination.new-family-cars',
      clickable: true,
    },
  });

  const swiperConsortium = new Swiper('.swiper-consortium', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: false,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next.consortium',
      prevEl: '.swiper-button-prev.consortium',
    },
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      }
    },
  });

  const swiperConsortiumAdvantages = new Swiper('.swiper-consortium-advantages', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next.consortium-advantages',
      prevEl: '.swiper-button-prev.consortium-advantages',
    },
    breakpoints: {
      992: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
    },
  });

  const swiperCarVersions = new Swiper('.swiper-car-versions', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination.car-versions',
      clickable: true,
    },
    thumbs: {
      swiper: swiperCarVersionsThumbs,
    }, 
  }); 

  const swiperCarVersionsThumbs = new Swiper('.swiper-car-versions-thumb', {
    slideToClickedSlide: true,
    slidesPerView: 'auto',
    watchSlidesProgress: true,
  });
  
  swiperCarVersions.controller.control = swiperCarVersionsThumbs;
  swiperCarVersionsThumbs.controller.control = swiperCarVersions;

  const swiperBmwInclusive = new Swiper('.swiper-bmw-inclusive', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    slidesPerView: 1,
    navigation: {
      nextEl: ".swiper-button-next.swiper-bmw-inclusive-nav-btn",
      prevEl: ".swiper-button-prev.swiper-bmw-inclusive-nav-btn",
    },
    thumbs: {
      swiper: swiperBmwInclusiveThumbs,
    }, 
  }); 

  const swiperBmwInclusiveThumbs = new Swiper('.swiper-bmw-inclusive-thumb', {
    slideToClickedSlide: true,
    slidesPerView: "auto",
    watchSlidesProgress: true,
  });
  
  swiperBmwInclusive.controller.control = swiperBmwInclusiveThumbs;
  swiperBmwInclusiveThumbs.controller.control = swiperBmwInclusive;

  const swiperCarDesignExterior = new Swiper('.swiper-car-design-exterior', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: "auto",
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-nav-next.design-exterior',
      prevEl: '.swiper-nav-prev.design-exterior',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiperCarDesignInterior = new Swiper('.swiper-car-design-interior', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: "auto",
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-nav-next.design-interior',
      prevEl: '.swiper-nav-prev.design-interior',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  });

  const swiperCarAddons = new Swiper('.swiper-car-addons', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination.addons',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 4,
      },
    },
  });

  const swiperUsedBenefits = new Swiper('.swiper-used-benefits', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1.2,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination.used-benefits',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next.used-benefits',
      prevEl: '.swiper-button-prev.used-benefits'
    },
    breakpoints: {
      768: {
        slidesPerView: 3.2,
      },
      992: {
        slidesPerView: 4,
      },
    },
  });


  const swiperTiresStamps = new Swiper('.swiper-tires-stamps', {
    slidesPerView: 1.4,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next.tires-stamps',
      prevEl: '.swiper-button-prev.tires-stamps'
    },
    pagination: {
      el: '.swiper-pagination.tires-stamps',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: 3.5,
        slidesPerGroup: 2
      },
    },
  });

  const swiperProductsLifestyle = new Swiper('.swiper-products-lifestyle', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1.2,
    spaceBetween: 10,
    navigation: {
      nextEl: '.swiper-button-next.products-lifestyle',
      prevEl: '.swiper-button-prev.products-lifestyle'
    },
    pagination: {
      el: '.swiper-pagination.products-lifestyle',
      clickable: true,
    },
    breakpoints: {
      768: {
        slidesPerView: 2.2,
      },
      992: {
        slidesPerView: 3.2,
      },
    },
  });

  const swiperPhotosLifestyle = new Swiper('.swiper-photos-lifestyle', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1.2,
    spaceBetween: 5,
    loop: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next.photos-lifestyle',
      prevEl: '.swiper-button-prev.photos-lifestyle'
    },
    pagination: {
      el: '.swiper-pagination.photos-lifestyle',
      clickable: true,
    },
    breakpoints: {
      992: {
        slidesPerView: 2.2,
      },
    },
  });

  const swiperListPhones = new Swiper('.swiper-list-phones', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next.list-phones-nav',
      prevEl: '.swiper-button-prev.list-phones-nav'
    },
    pagination: {
      el: '.swiper-pagination.list-phones-nav',
      clickable: true,
    },
    breakpoints: {
      440: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
      992: {
        slidesPerView: "auto",
      },
    },
  });

  const swiperMyApp = new Swiper('.in-page-slide-photos-js', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next.in-page-slide-photos-nav',
      prevEl: '.swiper-button-prev.in-page-slide-photos-nav'
    },
    pagination: {
      el: '.swiper-pagination.in-page-slide-photos-nav',
      clickable: true,
    },
  });

  const swiperInPageCards = new Swiper('.in-page-slide-card-js', {
    observer: true,
    observeParents: true,
    observeSlideChildren: true,
    watchOverflow: true,
    slidesPerView: 1.2,
    spaceBetween: 0,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next.in-page-slide-card-nav',
      prevEl: '.swiper-button-prev.in-page-slide-card-nav'
    },
    pagination: {
      el: '.swiper-pagination.in-page-slide-card-nav',
      clickable: true,
    },
    breakpoints: {
      530: {
        centeredSlides: false,
        slidesPerView: 2.1,
      },
      992: {
        centeredSlides: false,
        slidesPerView: 3,
      },
    },
  });

}

export function toggleAutoplay() {
  const playPauseButton = document.getElementById('playPauseButton');
  const paginationIconPause = document.getElementById('paginationIconPause');
  const paginationIconPlay = document.getElementById('paginationIconPlay');

  // Verifica se o swiperBanners está rodando
  if (swiperBanners.autoplay.running) {
    swiperBanners.autoplay.stop();
    paginationIconPause.classList.add('hidden');
    paginationIconPlay.classList.remove('hidden');
  } else {
    swiperBanners.autoplay.start();
    paginationIconPause.classList.remove('hidden');
    paginationIconPlay.classList.add('hidden');
  }
}

