// IMPORT LIBS
import Swiper from 'swiper';
import noUiSlider from 'nouislider';
import bootstrapBundle from '../../../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import "regenerator-runtime/runtime";

window.Swiper = Swiper;
window.noUiSlider = noUiSlider;
window.bootstrapBundle = bootstrapBundle;

// NEED TO USE "REQUIRE" IN THIS LIBRARY
const fancybox = require('../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox');
window.fancybox = fancybox;

// IMPORT FUNCTIONS
import { tabsCreate, tabsInit, setupNavTabsItemClick, setupNavTabsListToggle } from './functions/tabs';
import { swipersInit } from './functions/sliders';
import { showPartialsFixed } from './functions/partialsFixed';
import { buttonCotationBehavior, buttonFilterBehavior } from './functions/actionsForm';
import { showNavbarDropdown, showDropdownSub } from './functions/dropdowns';
import { sidebarFixedBehavior } from './functions/sidebarFixed';
import { controlDate } from './functions/controlDate';
import { getHeightDifference, handleSetMoreHeight } from './functions/setHeights';
import { chooseUnits } from './functions/chooseLocation';
import { toggleFormCaptation } from './functions/formSetup';
import { setupSearchModel, initialFetchFilteredCars, setupConditionCheckboxes, fetchFilteredCars } from './functions/actionsFilters';
import { onLoadNewCars } from './functions/actionsNewCars.js';
import { copyCtaBannerTitle, readMoreText } from './functions/randomActions.js';
import { modalInject, popUp, startModalListeners } from './functions/modal.js';
import { checkUrlParams, setupPremiumSelectionActions } from './functions/premiumSelectionActions.js';


  const bodyClassList = document.querySelector('body').dataset.page;
  const isUsedExternalLink = document.getElementById('used-external-link');

  if(!isUsedExternalLink){
    // Inicializando funções de filtro novos/seminovos se estiver na página de seminovos
    if ((bodyClassList === 'site-estoque-0km') || (bodyClassList === 'site-seminovos')) {
  
      var conditionVehicle = 'used';

      if(bodyClassList === 'site-seminovos'){        
        
        window.addEventListener('onFiltersLoaded', setupPremiumSelectionActions);
        window.addEventListener('onGetFilteredCars', checkUrlParams);

        setupPremiumSelectionActions();
      }
  
      if (bodyClassList === 'site-estoque-0km') {
        conditionVehicle = 'new';
      }
  
      setupSearchModel(conditionVehicle); 
      initialFetchFilteredCars();
    } else if (bodyClassList === 'site-bmw-seminovos-certificados') {
      const filters = {
        brand: 'BMW',
        limit: 4,
        optionals: "BMW Premium Selection"
      };
  
      fetchFilteredCars(filters)
      window.addEventListener('finishRender', enableCardWrapper);

      function enableCardWrapper(){
        let totalCars = document.getElementById('totalCars').innerText;
        
        if(totalCars && totalCars != '0'){
          let premiumSelectionCardsWrapper = document.getElementById('premiumSelectionCardsWrapper');
          premiumSelectionCardsWrapper.classList.remove('d-none'); 
        }
      }
    }
  
  }
  
  
  // Inicializando funções da páginas de novos modelos
  onLoadNewCars();

  getHeightDifference();

  // INIT LAZY LOADING
  // const observer = lozad(); // lazy loads elements with default selector as '.lozad'
  // observer.observe();

  // FUNCTIONS TO CREATE AND INIT TABS
  tabsCreate();
  tabsInit();
  setupNavTabsItemClick();
  setupNavTabsListToggle();
 
  // FUNCTION TO INIT CHOOSE LOCATION
  chooseUnits();

  // FUNCTION TO CONTROL DATEPICKER
  const dateTimeInputs = ['#hora-preferencial', '#dia-preferencial', '#services-date']; //Seletores dos inputs
  dateTimeInputs.forEach(selector => {
    const element = document.querySelector(selector);
    if (element) {
      element.addEventListener('click', function() {
        this.showPicker();
      });
    }
  });

  // FUNCTION TO CREATE AND INIT SWIPERS
  swipersInit();

 // FUNCTION TO CONTROL HEADER/FOOTER FIXED
 showPartialsFixed('header.header', 'show-header-scrolled', 'transparent-bg');
 showPartialsFixed('main.main', 'show-header-scrolled', 'transparent-bg');
 showPartialsFixed('.footer-fixed', 'show-footer-scrolled', '');

  // FUNCTION TO CONTROL BEHAVIOR OF COTATION BUTTONS
  buttonCotationBehavior('.button-call-form');
  buttonCotationBehavior('.button-product-cotation');
  buttonCotationBehavior('.button-services-cotation');
 
    // FUNCTION TO CONTROL BEHAVIOR OF FILTERS BUTTONS
    buttonFilterBehavior('.button-call-filter');

      // FUNCTION TO CONTROL FORM'S CAPTATION LOCATION
  toggleFormCaptation($('button[data-section]'));

   // FUNCTION TO CONTROL DROPDOWNS
   showNavbarDropdown('.navbar-link.mega_menu', '.navbar-dropdown.megaMenuDropdown', 0);
   showNavbarDropdown('.sidebar-link.mega_menu', '.navbar-dropdown.megaMenuDropdown', 0);
   showNavbarDropdown('.navbar-link.dropdownListBtn', '.dropdownList', 0);
   showDropdownSub('.dropdown-subtitle', '.dropdown-links-list', 0);
   showDropdownSub('.footer-content-title', '.footer-content-list', 0);
 
  // FUNCTION TO CONTROL BEHAVIOR OF SIDEBAR FIXED
  sidebarFixedBehavior('.btn-mainMenu', '.js-sidebar-button');
  sidebarFixedBehavior('.btn-sideMenu', '.js-sidebar-button');

  // FUNCTION TO INSERT CURRENT DATE IN INPUTS [TYPE=DATE]
  controlDate('input[type="date"]');


  //FUNCTION TO CHANGE FANCYBOX IN GALLERY NEW MODEL
  $(".thumbOutsideGalleryNewCars").on("click", function() {
    var imagePath = $(this).attr("src");
    $("#galleryOutsideFirstImage").attr("src", imagePath);
    $("#gallery-outside a.fancybox").attr("href", imagePath);
  });

  $(".thumbInsideGalleryNewCars").on("click", function() {
    var imagePath = $(this).attr("src");
    $("#galleryInsideFirstImage").attr("src", imagePath);
    $("#gallery-inside a.fancybox").attr("href", imagePath);
  });

  setFamily();
  getModelFromParams();
  changeTestDriveImage();

  // FUNCTION TO CONTROL BANNERS
  copyCtaBannerTitle('cta-banner-title', 'cta-banner-title-target');

  // FUNCTION TO CONTROL ACCORDIONS AND TEXTS
  readMoreText('read-more-cta', 'read-more-text');


window.addEventListener('resize', () => {
  getHeightDifference();
});

function setFamily(){
  let searchParams = new URLSearchParams(window.location.search);
  const modelname = searchParams.get('modelname');
  const selector = ".my-tabs[data-content='"+modelname+"']";
}

function getModelFromParams() {
  let searchParams = new URLSearchParams(window.location.search);
  const modelname = searchParams.get('modelname');

  if (modelname) {
    $('select#model').val(modelname);
    const imgWrapper = $('form .test-drive-form-img img');

    const img = $('select#model').find('option:selected').attr('data-image');
    imgWrapper.attr('src', img);
  }
}

function changeTestDriveImage() {
  const myInput = $('select#model');
  const imgWrapper = $('form .test-drive-form-img img');
  myInput.on('change', function (e) {
    const img = $(e.target).find('option:selected').attr('data-image');
    imgWrapper.attr('src', img);
  });
}


//Function to controll faq collapse
$('.faq-collapse-header').on('click', function(){
  $('.faq-collapse').not($(this).parent()).removeClass('open');
  
  $(this).parent().toggleClass('open');
});

// FUNCTION TO UP TOP PAGE
$('.btn-scroll-top').on('click', function () {
  $('html,body').animate({ scrollTop: 0 }, '300')
});

startModalListeners()

// FUNCTION TO CONTROL PAGINATIONS
let currentPage = 1;
let currentOrd = 'asc';
let order = 'id';

$('.order-control').on('click', function () {
  search(currentPage);
});

$('.page-link').on('click', function () {

  currentPage = $(this).data('page');
  $('.page-link').removeClass("active");
  $(this).addClass("active");
  search(currentPage);
});

$('.page-link').on('click', function () {
  currentPage = $(this).data('page');
  $('.page-item').removeClass("active");
  $(this.parentElement).addClass("active");

  if (currentPage != "1") {
    $('.left-arrow').removeClass("disabled");
  } else {
    $('.left-arrow').addClass("disabled");
  }

  search(currentPage);
});

function search(pageNum = 1) {
  async function busca() {
    currentOrd = $("#order").val();
    let method = $("#pageId").val();
    let category = $("#category").val();

    if(!order){
    order = 'id'
    }

    if(!currentOrd){
    currentOrd = 'asc'
    }

    if(method == 'products'){
      $('#promotions').css('filter', 'blur(5px)');
      const result = await $.request('onGetProducts', {
      data: {order: order,direction : currentOrd, page: pageNum, limit: 9, category: category},
      loading: '.loader-wrapper',
      complete: function(res) {
        $('#promotions').css('filter', 'unset');
        const observer = lozad(); // lazy loads elements with default selector as '.lozad'
        observer.observe();
      }
      });
      return result;
    }
    

      if(method == 'promotions'){
        const result = await $.request('onGetPromotions', {
        data: {order: currentOrd,page: pageNum, limit: 9},
        complete: function(res) {
          const observer = lozad(); // lazy loads elements with default selector as '.lozad'
          observer.observe();
        }
        });
        return result;
      }

  }

  $('#categories,#order').on('change', function (event) { // catch the form's submit event
    $.ajax({ // create an AJAX call...

        data: $('#formBlog').serialize(), // get the form data
        type: $(this).attr('GET'), // GET or POST
        url: "/blog-post-page", // the file to call
        success: function (response) { // on success..
          $('#here').html(response); // update the DIV
          const observer = lozad(); // lazy loads elements with default selector as '.lozad'
          observer.observe();
          handleSetMoreHeight('.js-blog-title', 992);
          handleSetMoreHeight('.js-blog-desc', 992);
        }
    });
    return false; // cancel original event to prevent form submitting
  });

  let a = busca().then( data => $("#promotions").html(data.result)).finally(
    function () {
      buttonCotationBehavior('.button-product-cotation');

      $('.btn-open-modal').on('click', function () {
        const el = $(this).data('target');
        const data = $(this).data('json');
        data ? modalInject(data, el) : popUp($(el));
      });

      $('.page-item').on('click', function () {
        const data = $(this).find('[data-page]').data('page')
        search(data);
      });
    }
  );
}

// OPEN MODAL VIDEO 
$('#mosaicgallery-video').on('click', function () {
  const videoSrc = $(this).data('target');
  $('#mosaicgallery-modal-video').find('source').attr('src', videoSrc);
  $('#mosaicgallery-modal-video').show();  
  var player = videojs('bmw-video');
  player.play();
})

// Fechar modal video
$('.overlay-close-modal').on('click', function () {
  var player = videojs('bmw-video');
  player.pause();
  $('#mosaicgallery-modal-video').hide();
})